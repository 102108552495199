export function debounce(ms,callback,state){
    return (...args)=>{
        if( !state ){
            state = true;
            setTimeout(()=>{
                state && callback(...args);
                state = false;
            },ms)
        }
    }
}

export function scrollTop(item){
    return 'scrollTop' in item ? item.scrollTop : (
        'scrollY' in item ? item.scrollY : 0
    );
}

export function scrollLeft(item){
    return 'scrollLeft' in item ? item.scrollLeft : (
        'scrollX' in item ? item.scrollX : 0
    );
}


export function scrollHeight(item){
    return (item === window ? document.body : item).scrollHeight
}

export function scrollWidth(item){
    return (item === window ? document.body : item).scrollWidth
}

export function Detail(){

}

Detail.prototype = {
    offset(element){
        let offset = element.getBoundingClientRect();
        return {
            initY: this.y + offset.top,
            endY : this.y + offset.top + offset.height
        }
    }
}

export default function onScroll( list,callback , ms = 200){
    list = [].concat(list);
    list.forEach((item)=>{
        let y = scrollTop(item),
            x = scrollWidth(item),
            yToggle = 'top',
            xToggle = 'left',
            detail = (event,recordToggle = true)=>{
                let scroll = new Detail;
                    scroll.height = scrollHeight(item);
                    scroll.width  = scrollWidth(item);
                    scroll.y  = scrollTop(item);
                    scroll.x  = scrollLeft(item);
                    scroll.directionY = scroll.y < y ? 'top'  : 'bottom';
                    scroll.directionX = scroll.x < x ? 'left' : 'right';

                    if( recordToggle ){
                        // set y
                        scroll.toggleY = scroll.directionY !== yToggle;
                        yToggle = scroll.directionY;
                        y = scroll.y;
                        // set  x
                        scroll.toggleX = scroll.directionX !== xToggle;
                        xToggle = scroll.directionX;
                        x = scroll.x;
                    }else{
                        scroll.toggleX = scroll.toggleY = true;
                    }
    
                    callback(scroll);
            }
        item.addEventListener('scroll',debounce(ms,detail))
        detail()
    })
}
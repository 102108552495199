export default function parseBlock( value, openTag, closedTag ){
    let groups  = [''],
        content = [''],
        parent  = [''],
        count   = 0,
        append  = 0,
        closed;
    
    let save = (collection,letter)=>{
        collection[count] =  (collection[count] || '')+ letter;
    }

    String(value)
    .split('')
    .forEach((letter)=>{
        let next;
       
        if( closed ){
            if( letter == openTag){
                append++;
            }
            if( letter == closedTag ){
                append--;
                if( !append ){
                    closed = false;
                    next   = true;
                }else{
                    save(content,letter);
                }
            }else{
                save(content,letter);
            }
        }else if(letter == openTag){
            append = 1;
            closed = true;
            
        }else{
            save(parent,letter);
        }
        save(groups,letter);
        
        if( next ) count++;
    })
    return {groups,content,parent}
}
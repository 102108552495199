import setAttr    from './setAttr';
import parseBlock from './parseBlock';

export function parseAttrs(content){
    let attrs = {};
    parseBlock(content,'[',']')
        .groups
        .map((block)=>{
            block
            .replace(/\[([\w\_\.\-]+)(?:\=(.+)){0,1}\]/g,(all,attr,value)=>{
                attrs[attr] = value;
                return '';
            })
            .replace(/(\.|\#)([\w\_\-]+)/g,(all,type,value)=>{
                if(type == '#'){
                    attrs.id = value;
                }else{
                    (attrs.className = attrs.className || []).push(value);
                }
                return '';
            })
        })
    return attrs;
}

export  function parseMedia(content){
    let medias = {},
        parse = parseBlock(content,'{','}');
        parse
        .groups
        .map((block,index)=>{
            let media = parseBlock(parse.parent[index],'(',')').content[0];
            if( media ){
                medias[media] = parseAttrs(parse.content[index]);
            }
        })
        return medias;
}

export function onMedia( media , fn){
    media = window.matchMedia(media);
    let match = ( event )=>{event.matches && fn( event )};
    media.addListener(match);
    match(media);
    return ()=>media.removeListener(match);
}

export default function setMedia( element ){
    [].concat(element)
      .forEach((element)=>{
            let attr  = element.getAttribute('media') || '',
                media = parseMedia(attr), general;
                Object
                .keys(media)
                .forEach((prop)=>{
                    onMedia(prop,(event)=>{
                        setAttr(element,media[prop]);
                    })
                })
      })
}
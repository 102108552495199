import pathToProp from './pathToProp';

export default function setAttr(element,attributes){
    [].concat(element)
      .forEach((element)=>{
            for( let prop in attributes ){
                let map    = pathToProp(prop),
                    cursor = element,
                    length = map.length -1 ;
                
                if( map.some((select,index)=>{
                    if( cursor && select in cursor ){
                        if( index === length ){
                            cursor[select] = attributes[prop];
                        }else{
                            cursor = cursor[select];
                        }
                    }else{
                        return true;
                    }
                }) ){
                    element.setAttribute(prop,attributes[prop]);
                }
            }
      })
}
export default function http(config,ready){
    let request = new XMLHttpRequest(),
        method  = (config.method||'GET' ).toUpperCase(),
        body    = config.body || {},
        link    = config.url || config.link || '',
        headers = config.headers || {};

        request.onreadystatechange = ()=>{
            if( request.readyState == 4 && request.status == 200 ){
                ready && ready(request);
            }
        }

        for( name in headers ) request.setRequestHeader(name,headers[name]);

        switch( method ){
            case 'GET':
                let params = [];
                if( typeof body == 'object' ){
                    for( var name in body) params.push(`${name}=${body[name]}`);
                }
                link = params.length ? '?'+params.join('&') : '';
            break;
            case 'POST':
                if( body instanceof HTMLElement ){
                    body = new FormData(body);
                }else{
                    body = new FormData;
                    for( var name in body ) body.append(name,body[name]);
                }
            break;
        }

        request.open( 
            method,
            link,
            true
        );

        request.send(body);

        return request;
}
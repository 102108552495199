import "./css/index.css";

//import {h,render} from 'preact';
//import {Shop} from './components/Shop';
import zenscroll from "zenscroll";

import {
  query,
  onScroll,
  toggleClass,
  setMedia,
  on,
  onWait,
  http,
  include,
  onReady
} from "./dom";

query("[media]").forEach(item => {
  setMedia(item);
});

query(".Header").every(element => {
  onScroll(
    window,
    (() => {
      let header = element.classList;
      return scroll => {
        if (scroll.y > 100) {
          !header.contains("Scroll-move") && header.toggle("Scroll-move", true);
        } else {
          header.contains("Scroll-move") && header.toggle("Scroll-move", false);
        }
      };
    })()
  );
});

// query('#productos').every((element)=>{
//     render(<Shop products={window.PRODUCTS} message={window.MESSAGE_PRODUCTS} consumer={window.AJAX_PRODUCTS}/> ,element)
// })

query("[data-toggle]").forEach(element => {
  let select = query("." + element.dataset.toggle);
  on(element, "click", () => {
    select.forEach(element => {
      let list = element.classList;
      list.toggle("Toggle", !list.contains("Toggle"));
    });
  });
});

query(".Form").forEach(form => {
  let api = "//api.upp.cl/forms/5a388c34e94c5ff4183438a7",
    message = form.querySelector(".InputMessage");

  form.onsubmit = onWait((event, done) => {
    message.textContent = "Enviado mensaje...";
    include(api, () => {
      http(
        {
          method: "post",
          link: api + "/" + SESSION,
          body: form
        },
        request => {
          message.textContent = "Mensaje Enviado!";
          let data = JSON.parse(request.response);
          if (data.ok) {
            message.textContent = "Mensaje Enviado!";
          } else {
            message.textContent =
              "Ocurrio un error, por favor intentalo nuevamente.";
            done();
          }
        }
      );
    });
  });
});

query("a").forEach(a => {
  a.addEventListener("click", event => {
    let href = a.getAttribute("href") || "";
    if (!document.querySelector(href)) location.href = __dirsite + href;
  });
});


onReady(()=>{
    query('video[data-src]').forEach((video)=>{
        video.src   = video.dataset.src;
        video.loop  = true;
        video.muted = true;
        video.playsinline = true;
        on(video,'canplaythrough',()=>{
            video.style.opacity = '1';
            video.play();
        })
    })
})

export var includes = [];

export function includeWait(src,fn){
    if( includes.some((log)=>log.src === src && log.ready) ){
        fn();
        return true;
    }else{
        includes.push({src,fn});
        return false;
    }
}

export function includeReady(src){
    includes.forEach((log)=>{
        if( log.src === src ){
            log.ready = true;
            log.fn();
        }
    })
}   

export default function include(src,fn){
    if( !includeWait(src,fn) ){
        let script = document.createElement('script');
            script.async  = true;
            script.onload = ()=>includeReady( src );
            script.src = src;
            document.head.appendChild(script);
    }   
}